import { useLocalStorage } from "@uidotdev/usehooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase-config";
import { signInWithEmailAndPassword } from "@firebase/auth";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user", null);
  const [alerts, setAlerts] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target["email"].value;
    const password = e.target["password"].value;

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      setUser(res);
      navigate("/");
    } catch (error) {
      setAlerts((prev) => [...prev, error]);
    }
  };

  const removeAlert = (alert) =>
    setAlerts((prev) => prev.filter((x) => x !== alert));

  return (
    <div>
      <Navbar/>
      <div style={{ display: "flex" }} className="mb-3">
        <form
          className="box"
          style={{ margin: "auto", minWidth: "400px", marginTop: "2rem" }}
          onSubmit={handleSubmit}
        >
          <h1 class="title">Log In!</h1>
          {alerts.map((alert, i) => (
            <div class="notification is-danger">
              <button
                onClick={() => removeAlert(alert)}
                class="delete"
              ></button>
              alert
            </div>
          ))}
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input"
                type="email"
                placeholder="Jaya@gmail.com"
                name="email"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <input
                class="input"
                type="password"
                placeholder="Something"
                name="password"
                required
              />
            </div>
          </div>
          <button className="button is-primary">Submit</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
