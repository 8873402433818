import "bulma/css/bulma.min.css";
import "./App.css";
import { db } from "./firebase/firebase-config";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import NewPainting from "./pages/NewPainting";
import Painting from "./pages/Painting";
import ThankYou from "./pages/ThankYou";
import Admin from "./pages/Admin";
import EditPainting from "./pages/EditPainting";
import EditProfile from "./pages/EditProfile";
import About from "./pages/About";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile">
          <Route path="" element={<Profile />} />
          <Route path=":id" element={<Profile />} />
        </Route>
        <Route path="/edit-profile/:id" element={<EditProfile />} />
        <Route path="/painting/:id" element={<Painting />} />
        <Route path="/edit-painting/:id" element={<EditPainting />} />
        <Route path="/new-painting" element={<NewPainting />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
};

export default App;
