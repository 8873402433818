// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTB358h5JER24HCzT9PeTPN3qFYZ0akes",
  authDomain: "jayz-39843.firebaseapp.com",
  projectId: "jayz-39843",
  storageBucket: "jayz-39843.appspot.com",
  messagingSenderId: "1052802832079",
  appId: "1:1052802832079:web:884faae8e13c258b8189ba",
  measurementId: "G-SCVZKPM509",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);