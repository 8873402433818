import { createUserWithEmailAndPassword } from "@firebase/auth";
import React, { useState } from "react";
import { auth, db } from "../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import Navbar from "../components/Navbar";
import { collection, doc, setDoc } from "@firebase/firestore";
import Footer from "../components/Footer";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const Signup = () => {
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user", null);
  const [alerts, setAlerts] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target["email"].value;
    const password = e.target["password"].value;
    const age = e.target["age"].value;
    const bio = e.target["bio"].value;
    const phone = e.target["phone"].value;
    const name = e.target["name"].value;

    if (!profilePhoto) {
      alert("Please upload a profile photo");
      return;
    }

    try {
      const storage = getStorage();

      const res = await createUserWithEmailAndPassword(auth, email, password);

      const userDoc = doc(collection(db, "users"), res.user.uid);

      const storageRef = ref(storage, `${res.user.uid}/profilePhoto`);
      const snapshot = await uploadBytes(storageRef, profilePhoto);
      const photo = storageRef.fullPath;

      setDoc(userDoc, {
        age,
        bio,
        phone,
        name,
        email,
        photo,
        approved: false,
      });
      setUser(res);
      navigate("/");
    } catch (error) {
      setAlerts((prev) => [...prev, error.code]);
    }
  };

  const removeAlert = (alert) =>
    setAlerts((prev) => prev.filter((x) => x !== alert));

  const uploadFile = (e) => {
    const selectedFiles = e.target.files;
    // Check if any files are selected
    if (selectedFiles.length !== 1) {
      alert("Please select at least one file to upload.");
      return;
    }

    setProfilePhoto(selectedFiles[0]);
  };

  return (
    <div>
      <Navbar />
      <div style={{ display: "flex" }}>
        <form
          className="box mb-5"
          style={{ margin: "auto", minWidth: "400px", marginTop: "2rem" }}
          onSubmit={handleSubmit}
        >
          <h1 class="title">Sign Up!</h1>
          {profilePhoto && (
            <figure class="image is-128x128 ml-auto mr-auto mb-6">
              <img
              className="is-rounded"
                src={URL.createObjectURL(profilePhoto)}
                alt="profile upload"
              />
            </figure>
          )}
          {alerts.map((alert, i) => (
            <div class="notification is-danger">
              <button
                onClick={() => removeAlert(alert)}
                class="delete"
              ></button>
              {alert}
            </div>
          ))}
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input"
                type="email"
                placeholder="Jaya@gmail.com"
                name="email"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <input
                class="input"
                type="password"
                placeholder="Something"
                name="password"
                required
              />
            </div>
          </div>
          <div class="file">
            <label class="file-label mb-2">
              <input
                class="file-input"
                type="file"
                name="image"
                multiple
                accept="image/*"
                onChange={uploadFile}
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">Upload Profile Photo</span>
              </span>
            </label>
          </div>
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Jaya"
                name="name"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Age</label>
            <div class="control">
              <input
                class="input"
                type="number"
                placeholder="15"
                name="age"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Bio</label>
            <div class="control">
              <textarea
                class="textarea"
                type="text"
                placeholder="Something about yourself..."
                name="bio"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Phone</label>
            <div class="control">
              <input
                class="input"
                type="number"
                placeholder="04"
                name="phone"
                required
              />
            </div>
          </div>
          <button className="button is-primary">Submit</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
