import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase-config";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import ArtistCard from "../components/ArtistCard";
import { downloadURL } from "../firebase/firebase-utils";
import Painting from "../components/Painting";

const Admin = () => {
  const [user, setUser] = useLocalStorage("user", null);
  const [users, setUsers] = useState([]);
  const [paintings, setPaintings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const permsQuery = query(
        collection(db, "perms"),
        and(where("isAdmin", "==", true), where("user", "==", user.user.uid))
      );
      const permsSnapshot = await getDocs(permsQuery);

      if (permsSnapshot.docs.length === 0) navigate("/");

      const usersQuery = query(
        collection(db, "users"),
        where("approved", "==", false)
      );
      const usersSnapshot = await getDocs(usersQuery);
      setUsers(
        usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );

      const paintingsQuery = query(
        collection(db, "paintings"),
        where("approved", "==", false)
      );
      const paintingsSnapshot = await getDocs(paintingsQuery);
      const paintings = await Promise.all(
        paintingsSnapshot.docs
          .map((painting) => ({ ...painting.data(), id: painting.id }))
          .map(async (painting) => {
            painting.thumbnail = await downloadURL(painting.images[0]);
            console.log(painting);
            return painting;
          })
      );
      setPaintings(paintings);
    };

    fetchData();
  }, [user.user.uid, navigate]);

  const approveUser = async (id) => {
    await updateDoc(doc(collection(db, "users"), id), {
      approved: true,
    });
    setUsers((prev) => prev.filter((user) => user.id !== id));
    window.location.reload();
  };

  const approvePainting = async (id) => {
    await updateDoc(doc(collection(db, "paintings"), id), {
      approved: true,
    });
    setPaintings((prev) => prev.filter((painting) => painting.id !== id));
    window.location.reload();
  };

  return (
    <div>
      <NavBar />
      <section className="section">
        <h1 class="title">Artist Approval</h1>
        <div className="m-3 is-flex gap-3">
          {users.map((user) => (
            <div className="box m-1">
              <ArtistCard artist={user.id} />
              <button
                onClick={() => approveUser(user.id)}
                className="button is-primary"
              >
                Approve
              </button>
            </div>
          ))}
        </div>
      </section>
      <section className="section">
        <h1 class="title">Paintings Approval</h1>
        <div className="m-3 is-flex gap-3">
          {paintings.map((painting) => (
            <div className="box">
              <Painting
                id={painting.id}
                title={painting.title}
                price={painting.price}
                artist={painting.artist}
                imageURL={painting.thumbnail}
              />
              <button
                onClick={() => approvePainting(painting.id)}
                className="button is-primary mt-3"
              >
                Approve
              </button>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Admin;
