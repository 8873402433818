import React from "react";
import Painting from "./Painting";

const PaintingsGrid = ({ paintings }) => {
  return (
    <div
      className="is-flex"
      style={{
        gap: 10,
        flex: 1,
        flexWrap: "wrap",
        backgroundColor: "#f9f9f9",
      }}
    >
      {paintings.map((painting) => (
        <Painting
          id={painting.id}
          title={painting.title}
          price={painting.price}
          artist={painting.artist}
          imageURL={painting.thumbnail}
        />
      ))}
    </div>
  );
};

export default PaintingsGrid;
