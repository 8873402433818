import { signOut } from "@firebase/auth";
import { useLocalStorage } from "@uidotdev/usehooks";
import React, { useState } from "react";
import { auth } from "../firebase/firebase-config";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [user, setUser] = useLocalStorage("user", null);

  const handleLogout = async () => {
    await signOut(auth);
    console.log("signed out");
    setUser(null);
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          JayZ Classes
        </a>
        <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setToggle((prev) => !prev)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${toggle ? "is-active" : ""}`}>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a
                class="button is-link"
                href="https://www.facebook.com/JayZArtClasses/"
              >
                <span class="icon is-small">
                  <i class="fa-brands fa-facebook-f"></i>
                </span>
              </a>
              <a class="button" href="https://www.instagram.com/jaya_fialok/">
                <span class="icon is-small">
                  <i class="fa-brands fa-instagram"></i>
                </span>
              </a>
              <a href="/about" className="button is-link">
                <strong>About</strong>
              </a>
              {user ? (
                <>
                  <a href="/profile" className="button is-primary">
                    <strong>Profile</strong>
                  </a>
                  <button className="button is-danger" onClick={handleLogout}>
                    <strong>Logout</strong>
                  </button>
                </>
              ) : (
                <>
                  <a href="/signup" className="button is-primary">
                    <strong>Sign up</strong>
                  </a>
                  <a href="/login" className="button">
                    <strong>Login</strong>
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
