import { collection, doc, getDoc, updateDoc } from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase/firebase-config";
import Navbar from "../components/Navbar";
import ArtistCard from "../components/ArtistCard";
import ReactImageGallery from "react-image-gallery";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import Footer from "../components/Footer";
import { useLocalStorage } from "@uidotdev/usehooks";

const Painting = () => {
  const [user, setUser] = useLocalStorage("user", null);
  const { id } = useParams();

  const [painting, setPainting] = useState();
  const [images, setImages] = useState();

  useEffect(() => {
    const fetchData = async () => {
      console.log(id);
      const snapshot = await getDoc(doc(db, "paintings", id));
      console.log(snapshot.exists());
      const painting = snapshot.data();
      painting.images = await Promise.all(
        painting.images.map((x) => getDownloadURL(ref(getStorage(), x)))
      );
      setPainting(painting);
    };
    fetchData();
  }, [id]);

  const changeSold = async () => {
    await updateDoc(doc(collection(db, "paintings"), id), {
      sold: !painting.sold,
    });
    setPainting((prev) => prev.sold = !prev.sold);
    window.location.reload();
  }

  return (
    <div>
      <Navbar />
      {painting && (
        <>
          <section className="hero is-primary">
            <div className="hero-body">
              <div className="box">
                <h2 className="title is-4">Images</h2>
                <ReactImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  items={painting.images.map((url) => ({
                    original: url,
                    thumbnail: url,
                  }))}
                />
              </div>
              <h1 className="title">{painting.title}</h1>
              <h2 className="subtitle">{painting.description}</h2>
              <div className={`notification subtitle ${painting.sold ? "is-danger" : "is-success"}`}>
                {painting.sold ? "Sold" : "Available"}
              </div>
              {user && user.user.uid === painting.artist && (
                <button onClick={changeSold} className="button is-danger mb-5">
                  {painting.sold ? "Set to Available" : "Set to Sold"}
                </button>
              )}
              <div className="tile is-ancestor">
                <div className="tile is-6 is-vertical is-parent">
                  <div className="tile is-child notification is-link">
                    <p className="title">Price</p>
                    <p className="subtitle">${painting.price}</p>
                  </div>
                  <div className="tile is-child notification is-danger">
                    <p className="title">Medium</p>
                    <p className="subtitle">{painting.medium}</p>
                  </div>
                </div>
                <div className="tile is-6 is-parent is-vertical">
                  <div className="tile is-child notification is-info">
                    <p className="title">Dimensions (cm)</p>
                    <p className="subtitle">
                      {painting.dimensions.width} (width) x{" "}
                      {painting.dimensions.height} (height) x{" "}
                      {painting.dimensions.depth} (depth)
                    </p>
                  </div>
                  <div className="tile is-child notification is-link">
                    <p className="title">Artist</p>
                    <ArtistCard artist={painting.artist} />
                    <p className="subtitle">{painting.phone}</p>
                  </div>
                </div>
              </div>
              <h1 className="is-size-4">
                <strong>
                  Want to purchase this painting? Call or email the artist!
                </strong>
              </h1>
            </div>
            {user && user.user.uid === painting.artist && (
              <a href={`/edit-painting/${id}`} className="float">
                <i className="fa fa-edit my-float"></i>
              </a>
            )}
          </section>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Painting;
