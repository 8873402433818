import React from "react";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>Jayz Classes Youth Art Club</strong> by{" "}
          <a href="https://www.linkedin.com/in/vinayak-fialok-743148228/">
            Vinayak Fialok
          </a>
          .
        </p>
      </div>
    </footer>
  );
};

export default Footer;
