import { collection, doc, getDoc, setDoc } from "@firebase/firestore";
import { useLocalStorage } from "@uidotdev/usehooks";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import ReactImageGallery from "react-image-gallery";
import { db } from "../firebase/firebase-config";
import Footer from "../components/Footer";

const EditPainting = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useLocalStorage("user", null);
  const [files, setFiles] = useState([]);
  const [painting, setPainting] = useState(undefined);

  if (!user || user.user.uid !== id) navigate("/");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await getDoc(doc(collection(db, "paintings"), id));
      const painting = snapshot.data();
      painting.images = await Promise.all(
        painting.images.map((x) => getDownloadURL(ref(getStorage(), x)))
      );
      setPainting(painting);
    };
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const title = e.target["title"].value;
    const price = e.target["price"].value;
    const medium = e.target["medium"].value;
    const dimensions = {
      width: e.target["width"].value,
      height: e.target["height"].value,
      depth: e.target["depth"].value,
    };
    const description = e.target["description"].value;

    if (files.length === 0) {
      alert("Please upload at least 1 image of your painting");
      return;
    }

    const storage = getStorage();
    const images = await Promise.all(
      files.map(async (file) => {
        const storageRef = ref(storage, `${user.user.uid}/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        return storageRef.fullPath;
        // const downloadURL = await getDownloadURL(storageRef);
      })
    );

    const paintingDoc = doc(
      collection(db, "paintings"),
      `${user.user.uid}_${title}`
    );
    const _ = await setDoc(paintingDoc, {
      title,
      price,
      medium,
      dimensions,
      images,
      description,
      artist: user.user.uid,
      approved: false,
    });

    navigate("/thankyou");
  };

  const uploadFile = (e) => {
    const selectedFiles = e.target.files;
    // Check if any files are selected
    if (selectedFiles.length === 0) {
      alert("Please select at least one file to upload.");
      return;
    }

    const newFiles = files.slice();
    for (let i = 0; i < selectedFiles.length; i++) {
      newFiles.push(selectedFiles[i]);
    }
    console.log(newFiles);
    setFiles(newFiles);
  };

  if (!user) {
    return (
      <div>
        <Navbar />
        <div class="notification is-danger">
          You must be logged in to view this page{" "}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      {painting && (
        <section className="section">
          <h1 class="title">Edit Painting</h1>
          {files.length > 0 && (
            <div className="box">
              <h2 className="title is-4">Images</h2>
              <ReactImageGallery
                showPlayButton={false}
                showFullscreenButton={false}
                items={files
                  .map((f) => URL.createObjectURL(f))
                  .map((url) => ({ original: url, thumbnail: url }))}
              />
            </div>
          )}
          <div class="notification is-danger">
            <strong>Make sure to reupload all photos</strong>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="file">
              <label class="file-label">
                <input
                  class="file-input"
                  type="file"
                  name="image"
                  multiple
                  accept="image/*"
                  onChange={uploadFile}
                />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">Upload Image</span>
                </span>
              </label>
            </div>
            <div class="field mt-3">
              <label class="label">Title</label>
              <div class="control">
                <input
                  defaultValue={painting.title}
                  class="input"
                  type="text"
                  placeholder="Your title"
                  name="title"
                  required
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Description</label>
              <div class="control">
                <textarea
                  defaultValue={painting.description}
                  class="textarea"
                  type="text"
                  placeholder="Something about the painting..."
                  name="description"
                  required
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Price</label>
              <div class="control">
                <input
                  defaultValue={painting.price}
                  class="input"
                  type="number"
                  placeholder="100"
                  name="price"
                  required
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Medium</label>
              <div class="control">
                <input
                  defaultValue={painting.medium}
                  class="input"
                  type="text"
                  placeholder="Acrylic..."
                  name="medium"
                  required
                />
              </div>
            </div>
            <div class="field">
              <div class="label">
                <label class="label">Dimensions</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <input
                    defaultValue={painting.dimensions.width}
                    class="input"
                    type="number"
                    placeholder="Width (cm)"
                    name="width"
                    required
                  />
                </div>
                <div class="field">
                  <input
                    defaultValue={painting.dimensions.height}
                    class="input"
                    type="number"
                    placeholder="Height (cm)"
                    name="height"
                    required
                  />
                </div>
                <div class="field">
                  <input
                    defaultValue={painting.dimensions.depth}
                    class="input"
                    type="number"
                    placeholder="Depth (cm)"
                    name="depth"
                    required
                  />
                </div>
              </div>
            </div>
            <button className="button is-primary">Submit</button>
          </form>
        </section>
      )}
      <Footer />
    </div>
  );
};

export default EditPainting;
