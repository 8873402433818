import React from "react";
import ArtistCard from "./ArtistCard";

const Painting = ({ id, title, price, artist, imageURL, className }) => {
  return (
    <div className={`card ${className}`} style={{ flexGrow: 1 }}>
      <div className="card-image">
        <figure className="image is-4by3">
          <img style={{ objectFit: "scale-down" }} className="has-background-dark" src={imageURL} alt="" />
        </figure>
      </div>
      <div className="card-content">
        <ArtistCard artist={artist} />
        <p class="title">{title}</p>
        <p>
          <strong>${price}</strong>
        </p>
      </div>
      <footer class="card-footer">
        <a
          href={`/painting/${id}`}
          class="card-footer-item"
        >
          View
        </a>
      </footer>
    </div>
  );
};

export default Painting;
