import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { and, collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../firebase/firebase-config";
import "../styles/Float.css";
import { useLocalStorage } from "@uidotdev/usehooks";
import Footer from "../components/Footer";
import HomeImage from "../assets/home_hero.jpg";
import { downloadURL } from "../firebase/firebase-utils";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Painting from "../components/Painting";
import ArtistCard from "../components/ArtistCard";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 2000, min: 800 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 800, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Home = () => {
  const [user, setUser] = useLocalStorage("user", null);
  const [paintings, setPaintings] = useState([]);
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const usersSnapshot = await getDocs(
        query(collection(db, "users"), where("approved", "==", true))
      );
      const ids = usersSnapshot.docs.map((doc) => doc.id);
      setUserIds(ids);
      const paintingsQuery = query(
        collection(db, "paintings"),
        and(where("approved", "==", true))
      );
      const res = await getDocs(paintingsQuery);
      const paintings = await Promise.all(
        res.docs
          .map((painting) => ({ ...painting.data(), id: painting.id }))
          .filter((painting) => ids.includes(painting.artist))
          .map(async (painting) => {
            painting.thumbnail = await downloadURL(painting.images[0]);
            console.log(painting);
            return painting;
          })
      );
      setPaintings(shuffleArray(paintings));
    };
    fetchData();
  }, []);

  return (
    <>
      <section
        style={{
          backgroundSize: "cover",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${HomeImage})`,
        }}
        className="hero is-primary is-medium"
      >
        <div className="hero-head">
          <Navbar />
        </div>
        <div className="hero-body">
          <p className="title">JayZ Youth Art Club</p>
          <p className="subtitle">
            The JayZ Youth Art Club is a vibrant community dedicated to
            showcasing and selling the imaginative creations crafted by children
            who participate in JayZ art classes. Through these classes, young
            artists explore their creativity and hone their skills, producing
            colorful and expressive artworks. The club provides a platform for
            these budding talents to exhibit their work, fostering a sense of
            pride and accomplishment while also offering opportunities for their
            art to be appreciated and collected by others. It serves as both a
            celebration of youthful creativity and a nurturing environment for
            budding artists to flourish.
          </p>
        </div>
      </section>
      <section className="section">
        <h1 class="title">Browse Students' Art</h1>
        <div className="box">
          <Carousel
            className="p-3"
            autoPlay
            infinite
            responsive={responsive}
          >
            {paintings.map((painting) => (
              <Painting
                className="m-3"
                id={painting.id}
                title={painting.title}
                price={painting.price}
                artist={painting.artist}
                imageURL={painting.thumbnail}
              />
            ))}
          </Carousel>
        </div>
        <h1 className="title mt-5">Artists</h1>
        <div className="box">
          <Carousel
            className=""
            pauseOnHover
            autoPlay
            infinite
            arrows={false}
            responsive={responsive}
          >
            {shuffleArray(userIds).map((id) => (
              <ArtistCard className="m-3" artist={id} />
            ))}
          </Carousel>
          <Carousel
            className=""
            pauseOnHover
            autoPlay
            infinite
            arrows={false}
            responsive={responsive}
          >
            {shuffleArray(userIds).map((id) => (
              <ArtistCard className="m-3" artist={id} />
            ))}
          </Carousel>
          <Carousel
            className=""
            pauseOnHover
            autoPlay
            infinite
            arrows={false}
            responsive={responsive}
          >
            {shuffleArray(userIds).map((id) => (
              <ArtistCard className="m-3" artist={id} />
            ))}
          </Carousel>
        </div>
        {user && (
          <a href="/new-painting" class="float">
            <i class="fa fa-plus my-float"></i>
          </a>
        )}
      </section>
      <Footer />
    </>
  );
};

export default Home;
