import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ThankYou = () => {
  return (
    <div>
      <Navbar />
      <section className="hero is-success is-large">
        <div className="hero-body">
            <h1 className="title">Thank You!</h1>
            <h2 className="subtitle">Once your submission has been reviewed, it will be available on the home page!</h2>
            <a href="/">Home</a>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ThankYou;
