import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import YoungArtistPrize from "../assets/young_artist_prize.png";
import InternationalYouth from "../assets/international_youth_exchange_art_ex.png";
import AnotherOne from "../assets/another_image.png";
import Jaya from "../assets/jaya.png";
import Malaysia from "../assets/malaysia_letter.png";
import MP from "../assets/another_image_2.png";
import KiwiPainting from "../assets/kiwi_painting.png";
import Elderly from "../assets/elderly.png";

const About = () => {
  return (
    <div>
      <Navbar />
      <section className="hero is-info">
        <div className="hero-body">
          <h1 className="title">About JayZ Art Club</h1>
          <p className="subtitle">
            Step into the vibrant world of JayZ Club, where the boundless
            creativity of talented high school children ignites the canvas of
            possibility. In an era where digital distractions vie for attention,
            these young artisans have dedicated themselves to nurturing their
            craft, honing their skills into masterpieces that captivate the
            soul.
            <br />
            <br />
            JayZ Club stands as a beacon of artistic expression, a sanctuary
            where imagination knows no bounds. Here, amidst a symphony of
            colours and forms, you will discover treasures to adorn every corner
            of your world. From enchanting garden poles that whisper tales of
            nature's beauty to captivating paintings that breathe life into your
            home, our collection encompasses a kaleidoscope of wonders.
            <br />
            <br />
            But JayZ Club is more than just a marketplace; it's a testament to
            the resilience and ingenuity of youth. With each purchase, you not
            only acquire a piece of art but also empower the next generation,
            fuelling their dreams and aspirations. By investing in their
            creations, you bestow upon them the invaluable gifts of financial
            independence and creative empowerment, ensuring that their talents
            continue to flourish, whatever paths they may tread.
            <br />
            <br />
            In a post-pandemic world, where mental health struggles loom large,
            art emerges as a beacon of hope. It's a lifeline that rescues hearts
            from the depths of despair, offering solace and inspiration in equal
            measure. Through JayZ Club, you become a part of this transformative
            journey, weaving a tapestry of support and encouragement for the
            future generation.
            <br />
            <br />
            <strong>
              Join us in championing the spirit of creativity and resilience.
              Together, let us illuminate the path forward, one brushstroke at a
              time.
            </strong>
          </p>
        </div>
      </section>
      <section className="section">
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-8">
            <div class="tile is-parent">
              <article class="tile is-child notification is-danger">
                <p class="title">About Jaya</p>
                <div class="content">
                  Hello, I am Jaya, and reflecting on the past 10 years of my
                  artistic journey with JayZ Art Classes fills me with immense
                  pride and gratitude. What began 10 years ago in January 2014
                  has evolved into a vibrant community of creativity, where art
                  is not just taught but celebrated. Witnessing the success
                  stories of JayZ Children, whose artworks have graced
                  prestigious exhibitions and won numerous competitions, is
                  truly fulfilling. Jayz Children had their initial exhibitions
                  in Parramaa art gallery for first 3 years and since then the
                  annual exhibition is held at other locations. In these years
                  Jayz Students have won various art competitions such as - Oz
                  kids, Blacktown council Young Artist award, Anzac art Award,
                  Threatened species art compeons, Operations art, Harmony Day
                  compeons, entry to royal easter art show, Penrith fire museum
                  art compeon and various other local and school art compeons.
                  The recent international recognition at the Korea
                  International Youth Art Exhibition is a highlight, emphasising
                  the global impact of our artistic endeavours.
                  <br />
                  <br />
                  At JayZ Art Classes, we believe in the transformative power of
                  art not only as a means of personal expression but also as a
                  source of comfort and hope. In our ongoing commitment to
                  community service, JayZ Children engaged in a heart-touching
                  project by donating their artworks to the young parents at
                  Blacktown Children's Hospital. Amidst the unprecedented
                  challenges posed by the COVID- 19 pandemic, JayZ children
                  demonstrated resilience and compassion. Engaging in the
                  creation of heart leers and cards for those in aged care.
                  Since then they forged international connections with children
                  of Rumah Kanak School in Malaysia. Sending leers, cards, and
                  gis to the beautiful children there. These moments remind me
                  of the profound positive influence art can have on individuals
                  and communities.
                  <br />
                  <br />
                  On a personal level, the Seniors Art Group initiated in memory
                  of my mother at Dennis Johnson Library in 2018, has become a
                  cherished weekly gathering of around 20 students.
                  Additionally, contributing paintings to the Harman
                  Foundation's annual fundraising reflects my commitment to
                  using art for meaningful causes.
                  <br />
                  <br />
                  Spent the covid time to enhance my skills, studying diploma in
                  Art therapy. This journey is not just about teaching art; it
                  is about fostering creativity, building connections, and
                  leaving a lasting impact on the lives of those I have the
                  privilege to touch. Here's to the continued success and growth
                  of JayZ Art Classes and the exciting possibilities that lie
                  ahead!
                  <br />
                  <br />
                  Kind Regards,
                  <br />
                  Jaya
                </div>
              </article>
            </div>
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child notification is-primary">
                  <figure class="image">
                    <p class="title">Young Artist Art Awards</p>
                    <p class="subtitle">
                      Marayiah, <i>The Extraordinary</i>
                    </p>
                    <img
                      src={AnotherOne}
                      style={{ borderRadius: 10 }}
                      alt="Young Artist Prize"
                    />
                  </figure>
                </article>
                <article class="tile is-child notification is-primary">
                  <figure class="image">
                    <img
                      src={MP}
                      style={{ borderRadius: 10 }}
                      alt="Young Artist Prize"
                    />
                  </figure>
                </article>
              </div>
              <div class="tile is-parent is-vertical">
                <article class="tile is-child notification is-info">
                  <p class="title">Young Artist Prize</p>
                  <p class="subtitle">
                    Jessica Grobler, <i>Heat View Escape!</i>
                  </p>
                  <figure class="image">
                    <img
                      src={YoungArtistPrize}
                      style={{ borderRadius: 10 }}
                      alt="Young Artist Prize"
                    />
                  </figure>
                </article>
                <article class="tile is-child notification is-info">
                  <figure class="image">
                    <img
                      src={KiwiPainting}
                      style={{ borderRadius: 10 }}
                      alt="Young Artist Prize"
                    />
                  </figure>
                </article>
              </div>
            </div>
          </div>
          <div class="tile is-vertical">
            <div className="tile is-parent">
              <article class="tile is-child notification is-success">
                <div class="content">
                  <p class="title">
                    Breonna Konidana, <i>Our Mother Nature</i>
                  </p>
                  <figure class="image">
                    <img
                      src={InternationalYouth}
                      style={{ borderRadius: 10 }}
                      alt="Young Artist Prize"
                    />
                  </figure>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article class="tile is-child notification is-danger">
                <div class="content">
                  <p class="title">Jaya</p>
                  <figure class="image">
                    <img src={Jaya} style={{ borderRadius: 10 }} alt="Jaya" />
                  </figure>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article class="tile is-child notification is-info">
                <div class="content">
                  <p class="title">Stanhope Library Seniors' paintings</p>
                  <figure class="image">
                    <img
                      src={Elderly}
                      style={{ borderRadius: 10 }}
                      alt="Eldery Paintings"
                    />
                  </figure>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article class="tile is-child notification is-success">
                <div class="content">
                  <p class="title">Letters to Malaysia</p>
                  <p className="subtitle">
                    Every year Jaya's students send letters and gifts to an
                    orphanage in Malaysia to inspire life long friendships and
                    spread joy.
                  </p>
                  <figure class="image">
                    <img
                      src={Malaysia}
                      style={{ borderRadius: 10 }}
                      alt="Malaysia"
                    />
                  </figure>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
