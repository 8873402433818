import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase-config";
import { collection, doc, getDoc } from "@firebase/firestore";
import {downloadURL} from "../firebase/firebase-utils";

const ArtistCard = ({ artist, className }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await getDoc(doc(collection(db, "users"), artist));
      const user = snapshot.data();
      const image = await downloadURL(user.photo);
      setImage(image);
      setName(user.name);
      setEmail(user.email);
    };
    fetchData();
  }, [artist]);

  return (
    <div class={"media box " + className} style={{ minHeight: 120 }}>
      <div class="media-left">
        <figure class="image is-48x48">
          <img
            width="48px"
            height="48px"
            style={{
              objectFit: "contain"
            }}
            src={image}
            alt=""
          />
        </figure>
      </div>
      <div class="media-content">
        <a href={`/profile/${artist}`} class="title is-4 is-block has-text-link">{name}</a>
        <p class="subtitle is-6 has-text-dark">{email}</p>
      </div>
    </div>
  );
};

export default ArtistCard;
