import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { db } from "../firebase/firebase-config";
import Footer from "../components/Footer";
import PaintingsGrid from "../components/PaintingsGrid";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { downloadURL } from "../firebase/firebase-utils";

const Profile = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [user, setUser] = useLocalStorage("user", null);
  const [userDetails, setUserDetails] = useState();
  const [paintings, setPaintings] = useState();
  id = id ?? user.user.uid;

  useEffect(() => {
    const storage = getStorage();

    const fetchData = async () => {
      // fetch the current user if id is not present
      const res = await getDoc(doc(db, "users", id ?? user.user.uid));
      const userData = res.data();
      userData.photo = await downloadURL(userData.photo);

      const paintingsQuery = query(
        collection(db, "paintings"),
        where("artist", "==", id ?? user.user.uid)
      );
      const querySnapshot = await getDocs(paintingsQuery);
      let paintings = [];
      querySnapshot.forEach((d) => paintings.push(d));

      paintings = await Promise.all(
        paintings
          .map((painting) => ({ ...painting.data(), id: painting.id }))
          .map(async (painting) => {
            painting.thumbnail = await downloadURL(painting.images[0]);
            return painting;
          })
      );

      setPaintings(paintings);
      setUserDetails(userData);
    };
    fetchData();
  }, [id, user]);

  if (!user && !id) navigate("/");

  return (
    <div>
      <Navbar />
      {userDetails && (
        <>
          <section className="hero is-primary">
            <div className="hero-body">
              <div class="tile is-ancestor m-5">
                <div className="tile is-4 is-vertical is-parent">
                  <div className="tile is-child">
                    <figure class="block image">
                      <img
                        style={{ height: 300, width: 300, objectFit: "cover" }}
                        className="is-rounded"
                        src={userDetails.photo}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="tile is-child">
                    <p className="title">{userDetails.name}</p>
                    <p className="subtitle">{userDetails.bio}</p>
                  </div>
                </div>
                <div class="tile is-4 is-vertical is-parent">
                  <div class="tile is-child notification is-info">
                    <p class="title">Age</p>
                    <p class="subtitle">{userDetails.age}</p>
                  </div>
                  <div class="tile is-child notification is-danger">
                    <p class="title">Phone</p>
                    <p class="subtitle">{userDetails.phone}</p>
                  </div>
                </div>
                <div class="tile is-parent">
                  <div class="tile is-child notification is-dark">
                    <p class="title">Email</p>
                    <p class="subtitle">{userDetails.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <h1 class="title">Browse {userDetails.name}'s Paintings</h1>
            {paintings && <PaintingsGrid paintings={paintings} />}
          </section>
        </>
      )}
      {user && user.user.uid === id && (
        <a href={`/edit-profile/${id}`} class="float">
          <i class="fa fa-edit my-float"></i>
        </a>
      )}
      <Footer />
    </div>
  );
};

export default Profile;
